export const ApplicationRoutes = {
  SERVICES: "/services",
  DATA: "/datasets",
  IDENTIFICATION_DOCUMENTS: "/documents",
};

export const StaticPages = {
  ABOUT_APPLICATION: "about_application",
  CONTACTS: "contacts",
  INFORMATIONS_ABOUT_PROCESSING_OF_PERSONAL_DATA:
    "informations_about_processing_of_personal_data",
};

export const ConfirmBottomDialogType = {
  DELETE: "delete",
  INFORMATION: "information",
  SUCCESS: "success",
};
