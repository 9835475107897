import { useEffect, useState } from "react";

export default function usePreviousPage() {
  const [previousPage, setPreviousPage] = useState(null);
  useEffect(() => {
    if (!localStorage) return;
    const url = localStorage.getItem("previousPage");
    setPreviousPage(url || null);
  }, []);
  return previousPage;
}
