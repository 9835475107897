import { useSession } from "mou-common";
import Router from "next/router";
import { useEffect } from "react";

export const SESSION_STATES = {
  LOGGED_IN: "LOGGED_IN",
  LOGGED_OUT: "LOGGED_OUT",
  NOT_REGISTERED: "NOT_REGISTERED",
};

export async function redirectBasedOnSessionState(
  sessionIsLoggedIn,
  sessionRequestInProgress,
  redirectIfSessionState,
  location,
  sessionWebId
) {
  if (sessionRequestInProgress) {
    return;
  }

  if (
    sessionIsLoggedIn &&
    redirectIfSessionState === SESSION_STATES.LOGGED_IN
  ) {
    await Router.push(location);
  }

  if (
    !sessionIsLoggedIn &&
    redirectIfSessionState === SESSION_STATES.LOGGED_OUT
  ) {
    await Router.push(location);
  }

  if (
    sessionIsLoggedIn &&
    !sessionWebId &&
    redirectIfSessionState === SESSION_STATES.NOT_REGISTERED
  ) {
    await Router.push(location);
  }
}

export function useRedirectIfLoggedOut(location = "/login") {
  const { session, sessionRequestInProgress } = useSession();

  useEffect(() => {
    redirectBasedOnSessionState(
      session.info.isLoggedIn,
      sessionRequestInProgress,
      SESSION_STATES.LOGGED_OUT,
      location
    );
  });
}

export function useRedirectIfLoggedIn(location = "/") {
  const { session, sessionRequestInProgress } = useSession();

  useEffect(() => {
    redirectBasedOnSessionState(
      session.info.isLoggedIn,
      sessionRequestInProgress,
      SESSION_STATES.LOGGED_IN,
      location
    );
  });
}

export function useRedirectIfNotRegistered(location = "/register") {
  const { session, sessionRequestInProgress } = useSession();

  useEffect(() => {
    redirectBasedOnSessionState(
      session.info.isLoggedIn,
      sessionRequestInProgress,
      SESSION_STATES.NOT_REGISTERED,
      location,
      session.info.webId
    );
  });
}
