import {
  useSession,
  useMouPodStructure,
  usePodIrisFromWebId,
  usePodFromProfile,
  useEnvConfig,
  BottomMenuItems,
  DatasetsListContext,
} from "mou-common";
import { useRouter } from "next/router";
import React, { useEffect, useContext, useMemo } from "react";
import useIssuePersonalPreferencesAG from "../../../src/hooks/useIssuePersonalPreferencesAG";
import { ApplicationRoutes } from "../../../constants/enums";
import {
  useRedirectIfLoggedOut,
  useRedirectIfNotRegistered,
} from "../../../src/effects/auth";
import usePreviousPage from "../../../src/hooks/usePreviousPage";
import Spinner from "../../spinner";
import useIndexer from "../../../src/hooks/useIndexer";

export default function Home() {
  const router = useRouter();
  const previousPage = usePreviousPage();
  useRedirectIfLoggedOut();
  // If webId not present in accessToken then redirect to register page
  useRedirectIfNotRegistered();

  const { session } = useSession();
  const { webId = "" } = session.info;
  const { data: podIris = [] } = usePodIrisFromWebId(webId);
  const [podIri] = podIris;
  const redirectUrl = previousPage || null;

  const { preparingPod } = useMouPodStructure();
  const { isIndexerEnabled, indexInbox } = useIndexer();
  const { pod } = usePodFromProfile();
  const { getBottomMenuItems } = useEnvConfig();

  useEffect(() => {
    if (pod && podIri && isIndexerEnabled) {
      indexInbox(podIri);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pod, podIri, isIndexerEnabled]);

  // isLoading is needed for useIssuePersonalPreferencesAG so redirect is not called immediately
  const { isLoading: isDatasetsLoading } = useContext(DatasetsListContext);

  const isLoading = preparingPod || isDatasetsLoading;

  useIssuePersonalPreferencesAG();

  const enabledBottomMenuItems = useMemo(() => {
    return getBottomMenuItems();
  }, [getBottomMenuItems]);

  useEffect(() => {
    if (preparingPod || isLoading) {
      return;
    }
    if (redirectUrl && redirectUrl !== "/") {
      router.push(redirectUrl).catch((e) => {
        throw e;
      });
    }
    if (podIri) {
      switch (true) {
        case enabledBottomMenuItems.includes(BottomMenuItems.DOCUMENTS):
          router.push(ApplicationRoutes.IDENTIFICATION_DOCUMENTS).catch((e) => {
            throw e;
          });
          break;
        case enabledBottomMenuItems.includes(BottomMenuItems.DATASETS):
          router.push(ApplicationRoutes.DATA).catch((e) => {
            throw e;
          });
          break;
        case enabledBottomMenuItems.includes(BottomMenuItems.SERVICES):
          router.push(ApplicationRoutes.SERVICES).catch((e) => {
            throw e;
          });
          break;
        default:
          console.error("No Bottom Menu Items configured!");
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [podIri, redirectUrl, preparingPod, isLoading]);

  return isLoading ? <Spinner /> : null;
}
