import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
import styles from "./styles.module.scss";

export default function Spinner({ opaque, rootClassName, spinnerClassName }) {
  return opaque ? (
    <div
      className={clsx(styles.opaqueContainer, rootClassName && rootClassName)}
    >
      <CircularProgress className={spinnerClassName && spinnerClassName} />
    </div>
  ) : (
    <Box className={clsx(styles.box, rootClassName && rootClassName)}>
      <CircularProgress className={spinnerClassName && spinnerClassName} />
    </Box>
  );
}

Spinner.propTypes = {
  opaque: PropTypes.bool,
  rootClassName: PropTypes.string,
  spinnerClassName: PropTypes.string,
};

Spinner.defaultProps = {
  opaque: false,
  rootClassName: undefined,
  spinnerClassName: undefined,
};
