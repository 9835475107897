import { useContext } from "react";
import {
  DatasetsListContext,
  ServiceMoucmnTypes,
  UsageLimitMouvcTypes,
} from "mou-common";
import useServiceLinks from "../useServiceLinks";

export default function useIssuePersonalPreferencesAgApi() {
  const { linkService } = useServiceLinks();
  const { personalPreferencesDatasets } = useContext(DatasetsListContext);

  const issuePersonalPreferencesAG = async () => {
    const { serviceId } = personalPreferencesDatasets[0].metadata;
    console.log(
      `Issue access grant for personal preferences service: ${serviceId}`
    );
    try {
      await linkService(
        serviceId,
        ServiceMoucmnTypes.PERSONAL_PREFERENCES,
        UsageLimitMouvcTypes.AFTER_CHANGE,
        false
      );
    } catch (error) {
      console.error(error);
    }
  };

  return { issuePersonalPreferencesAG };
}
