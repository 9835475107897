import { useEffect, useContext } from "react";
import {
  useSession,
  usePodIrisFromWebId,
  usePodFromProfile,
  DatasetsListContext,
} from "mou-common";
import useIssuePersonalPreferencesAgApi from "../useIssuePersonalPreferencesAgApi";

export default function useIssuePersonalPreferencesAG() {
  const { personalPreferencesDatasets } = useContext(DatasetsListContext);
  const { session } = useSession();
  const { webId = "" } = session.info;
  const { data: podIris = [] } = usePodIrisFromWebId(webId);
  const [podIri] = podIris;

  const { pod } = usePodFromProfile();

  const { issuePersonalPreferencesAG } = useIssuePersonalPreferencesAgApi();

  useEffect(() => {
    if (pod && podIri && (personalPreferencesDatasets || []).length > 0) {
      issuePersonalPreferencesAG();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pod, podIri, personalPreferencesDatasets]);

  return { issuePersonalPreferencesAG };
}
